import { InfoCircleOutlined } from "@ant-design/icons";
import { Input, Tabs, TabsProps, Tooltip, Typography } from "antd";
import clsx from "clsx";
import { useState } from "react";
import { BaseChartProps, DateFilterEnum } from "..";
import { ProductId } from "../../../features/API/types";
import { ThemeType } from "../utils";
import AnnualRevenuePieChart from "./AnnualRevenuePieChart";
import CompletionPercentageColumnChart from "./CompletionPercentageColumnChart";
import styles from "./ContainerBase.module.scss";
import CopilotSearchColumnChart from "./CopilotSearchColumnChart";
import KLSearchColumnChart from "./KLSearchColumnChart";
import KLUpdatesColumnChart from "./KLUpdatesColumnChart";
import PendingQuestionsColumnChart from "./PendingQuestionsColumnChart";
import tabStyles from "./TabsStyles.module.scss";
import TimeSavedColumnChart from "./TimeSavedColumnChart";

enum TabKey {
  TimeSaved = "timeSaved",
  CompletionPercentage = "completionPercentage",
  PendingQuestions = "pendingQuestions",
  SearchesOnCoPilot = "searchesOnCoPilot",
  SearchesOnKL = "searchesOnKL",
  KLUpdate = "klUpdate",
  AnnualRevenue = "annualRevenue",
}

export interface ConceirgeChartProps extends BaseChartProps {
  productId: ProductId;
}

interface ConciergeAnalyticsContainerProps {
  productId: ProductId;
  activeFilter: DateFilterEnum;
  theme: ThemeType;
  bigQueryAnalyticsSearchEnabled?: boolean;
  height: number;
  width: number;
}
const ConciergeAnalyticsContainer = ({
  productId,
  activeFilter,
  theme,
  bigQueryAnalyticsSearchEnabled,
  height,
  width,
}: ConciergeAnalyticsContainerProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<TabKey>(TabKey.TimeSaved);
  const [timeSavedInputValue, setTimeSavedInputValue] = useState<number>(2);
  const onChange = (key: TabKey) => {
    setSelectedTab(key);
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "-") {
      e.preventDefault();
    }
    const value = parseFloat((e.target as HTMLInputElement).value);
    if (!isNaN(value) && value > 0) {
      setTimeSavedInputValue(value);
    }
  };
  const items: TabsProps["items"] = [
    {
      key: TabKey.TimeSaved,
      label: "Time Saved",
      children: (
        <div className={styles.TimeSavedChartContainer}>
          <div className={styles.TimeSavedHeader}>
            <div className={styles.TimeSavedHeaderInputContainer}>
              <div className={styles.TimeSavedHeaderInputTitleContainer}>
                <Typography.Text className={styles.TimeSavedHeaderInputTitle}>
                  Estimated hours
                </Typography.Text>
                <Tooltip
                  title="Estimated time (in hours) it would take for you to complete 100 security questions."
                  placement="topRight"
                >
                  <InfoCircleOutlined className={styles.TimeSavedHeaderIcon} />
                </Tooltip>
              </div>
              <div className={styles.TimeSavedHeaderInput}>
                <Input
                  defaultValue={timeSavedInputValue}
                  type="number"
                  min={1}
                  max={1000}
                  placeholder="Hrs"
                  onKeyUp={handleKeyPress}
                />
              </div>
            </div>
          </div>
          <div className={styles.TimeSavedColumnChart}>
            <TimeSavedColumnChart
              productId={productId}
              activeFilter={activeFilter}
              timePerHundredQuestions={timeSavedInputValue}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        </div>
      ),
    },
    {
      key: TabKey.CompletionPercentage,
      label: "Completion Percentage",
      children: (
        <div className={styles.AnalyticsChart}>
          <CompletionPercentageColumnChart
            productId={productId}
            activeFilter={activeFilter}
            theme={theme}
            height={height}
            width={width}
          />
        </div>
      ),
    },
    {
      key: TabKey.PendingQuestions,
      label: "Pending Questions",
      children: (
        <div className={styles.AnalyticsChart}>
          <PendingQuestionsColumnChart
            productId={productId}
            activeFilter={activeFilter}
            theme={theme}
            height={height}
            width={width}
          />
        </div>
      ),
    },
    ...(bigQueryAnalyticsSearchEnabled
      ? [
          {
            key: TabKey.SearchesOnCoPilot,
            label: "Searches on Copilot",
            children: (
              <div className={styles.AnalyticsChart}>
                <CopilotSearchColumnChart
                  productId={productId}
                  activeFilter={activeFilter}
                  theme={theme}
                  height={height}
                  width={width}
                />
              </div>
            ),
          },
          {
            key: TabKey.SearchesOnKL,
            label: "Searches on Knowledge Library",
            children: (
              <div className={styles.AnalyticsChart}>
                <KLSearchColumnChart
                  productId={productId}
                  activeFilter={activeFilter}
                  theme={theme}
                  height={height}
                  width={width}
                />
              </div>
            ),
          },
        ]
      : []),
    {
      key: TabKey.KLUpdate,
      label: "Knowledge Library Updates",
      children: (
        <div className={styles.AnalyticsChart}>
          <KLUpdatesColumnChart
            productId={productId}
            activeFilter={activeFilter}
            theme={theme}
            height={height}
            width={width}
          />
        </div>
      ),
    },
    {
      key: TabKey.AnnualRevenue,
      label: "Annual Revenue",
      children: (
        <div className={styles.AnalyticsChart}>
          <AnnualRevenuePieChart
            productId={productId}
            activeFilter={activeFilter}
            theme={theme}
            height={height}
            width={width}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={clsx(styles.AnalyticsContainer, tabStyles.TabsContainer)}>
      <Tabs
        key={activeFilter}
        defaultActiveKey={TabKey.TimeSaved}
        activeKey={selectedTab}
        onChange={(key: string) => onChange(key as TabKey)}
        items={items}
      />
    </div>
  );
};

export default ConciergeAnalyticsContainer;
